import React from "react";
import Preloader from "./Preloader";

import S from "./FullScreenPreloader.module.scss";

const FullScreenPreloader = () => {
  return (
    <div className={S.root}>
      <Preloader />
    </div>
  );
};

export default FullScreenPreloader;
