import React from "react";

import S from "./EmptyPageLoader.module.scss";
import Preloader from "./Preloader";

const EmptyPageLoader = () => {
  return (
    <div className={S.root}>
      <Preloader />
    </div>
  );
};

export default EmptyPageLoader;
