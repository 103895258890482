import getClient from "../client";

import {
  API_DELETE_ALL_NOTIFICATIONS_PATH,
  API_NOTIFICATIONS_PATH,
  apiDeleteNotificationPath,
} from "../routes/user";

export default {
  state: [],

  reducers: {
    updateState: (state, notifications) => [...state, ...notifications],
    setState: (state, notifications) => notifications,

    delete: (state, id) => state.filter(n => n.id !== id),
    deleteAll: () => [],

    deleteDojoActivityRelated: (state, dojoId) => {
      // used in activities#delete redux
      const isDojoRelated = n => n.notifiableType === "DojoActivity" && n.notifiableId === dojoId;

      return state.filter(n => !isDojoRelated(n));
    },
  },

  effects: dispatch => ({
    async loadAsync() {
      const { data } = await getClient().get(API_NOTIFICATIONS_PATH);

      if (data && data.notifications) {
        dispatch.notifications.setState(data.notifications);
      }
    },

    async deleteAsync(id) {
      const path = apiDeleteNotificationPath(id);
      await getClient().delete(path);
      dispatch.notifications.delete(id);
    },

    async deleteAllAsync() {
      await getClient().delete(API_DELETE_ALL_NOTIFICATIONS_PATH);
      dispatch.notifications.deleteAll();
    },
  }),
};
