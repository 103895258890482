import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { jwtDecode } from "jwt-decode";

import S from "./ChooseTenantModal.module.scss";

const ChooseTenantModalMeta = ({ isOpen, setIsOpen }) => {
  const [tenants, setTenants] = useState({});

  useEffect(() => {
    const isValidJsonArray = str => {
      try {
        var json = JSON.parse(str);
        return typeof json === "object" && json !== null && json.length > 0;
      } catch (e) {
        return false;
      }
    };

    const meta = localStorage.getItem("meta");

    if (isValidJsonArray(meta)) {
      const metaArr = JSON.parse(meta);

      prepareTenantData(metaArr);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, []);

  const prepareTenantData = metaTokens => {
    const tenantsData = {};

    metaTokens.forEach(metaToken => {
      const decodedToken = jwtDecode(metaToken);

      tenantsData[decodedToken.tid] = { jwt: metaToken, tenant: decodedToken.tenant };
    });

    setTenants(tenantsData);
  };

  const handleSelectTenant = tid => {
    const selectedTenant = tenants[tid].jwt;

    localStorage.setItem("meta", selectedTenant);

    setIsOpen(false);
    window.location.reload();
  };

  return (
    <Modal
      backdropClassName={S.backdrop}
      backdrop={true}
      className={S.root}
      isOpen={isOpen}
      centered
    >
      <ModalHeader tag="div">Which tenant would you like to log into?</ModalHeader>

      <p className={S.subHeader}>You may switch tenants at any time from your Account Profile.</p>

      <ModalBody testkey="select-tenant-modal">
        {tenants &&
          Object.keys(tenants).map(tid => {
            return (
              <Button
                key={tid}
                className={S.button}
                block
                outline
                testkey={`select-tenant-button-${tenants[tid].tenant}`}
                onClick={() => handleSelectTenant(tid)}
              >
                {tenants[tid].tenant}
              </Button>
            );
          })}
      </ModalBody>
    </Modal>
  );
};

ChooseTenantModalMeta.propTypes = {
  isOpen: T.bool.isRequired,
  setIsOpen: T.func.isRequired,
};

export default ChooseTenantModalMeta;
