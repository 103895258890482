import React from "react";
import { Spinner } from "reactstrap";
import T from "prop-types";
import C from "classnames";

import S from "./Preloader.module.scss";

const Preloader = ({
  className = "",
  borderClassName = "",
  text = "Loading...",
  showLoaderText = true,
  shouldBeHidden = false,
  light = false,
  centered = false,
}) => {
  return (
    <div
      className={C(S.loading, { [S.light]: light }, { withoutText: showLoaderText }, className, {
        [S.centered]: centered,
      })}
      aria-hidden={shouldBeHidden}
    >
      <Spinner animation="border" className={C(S.border, { [S.light]: light }, borderClassName)} />
      {showLoaderText && <span>{text}</span>}
    </div>
  );
};

Preloader.propTypes = {
  className: T.string,
  borderClassName: T.string,
  text: T.string,
  showLoaderText: T.bool,
  shouldBeHidden: T.bool,
  light: T.bool,
  centered: T.bool,
};

export default Preloader;
