import store from "../store";

const DURATION = 5000;

export default {
  state: {
    // message => timer
  },

  reducers: {
    add(state, errorMessage) {
      // only non-empty string messages
      if (!errorMessage && typeof errorMessage !== "string") {
        return state;
      }

      const newState = { ...state };
      const timer = state[errorMessage];

      if (timer) {
        // reinit if same as first error
        clearTimeout(timer);
        newState[errorMessage] = setTimeout(() => {
          store.dispatch.errors.removeError(errorMessage);
        }, DURATION);
      } else {
        // add only if error is not present
        newState[errorMessage] = setTimeout(() => {
          store.dispatch.errors.removeError(errorMessage);
        }, DURATION);
      }

      return newState;
    },

    removeError: (state, message) => {
      const newState = { ...state };
      const timer = newState[message];
      clearTimeout(timer);
      delete newState[message];
      return newState;
    },
  },
};
