import {
  fetchErrorToastHandler,
  generateMessageFromStatus,
  networkErrorToastHandler,
} from "../utils/httpErrorHandler";

export const errorHandler = (error, options) => {
  if (error.response) {
    const { status, data } = error.response;

    if (data && data.isBalanceEmpty) {
      //FIGURE OUT WHAT THE STORE MEANS
      // store.dispatch.site.setIsBalanceEmpty(true);
    } else if (status && options) {
      const { useToast } = options;
      const message = generateMessageFromStatus({ status, data });
      fetchErrorToastHandler({ message, status, useToast });
    }
  } else if (error.message === "Network Error") {
    networkErrorToastHandler();
  }
};
