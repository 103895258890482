export const SCORM_PATH_PREFIX = "/guest";
export const SCORM_ERROR_PATH = "/error";
export const FULL_SCORM_ERROR_PATH = `${SCORM_PATH_PREFIX}${SCORM_ERROR_PATH}`;

export const SCORM_PATH = "/:id";
export const INITIAL_SCORM_PATH = "/:magicKey/:lessonKey";
export const SCORM_LESSON_PATH = "/belts/:courseId/modules/:lessonId/:step";
export const SCORM_DIRECT_LESSON_PATH = "/modules/:lessonId/:step";

export const scormLessonPath = (levelId, lessonId, step = "lesson") =>
  `${SCORM_PATH_PREFIX}/belts/${levelId || 1}/modules/${lessonId}/${step}`;

export const scormDirectLessonPath = (lessonId, step = "lesson") =>
  `/modules/${lessonId}${step ? `/${step}` : ""}`;
