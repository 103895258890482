import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { APP_HOST } from "../constants/urls";
import { IS_DEPLOYMENT_MODE } from "../constants/env";

export const runDataLog = () => {
  // preempt as much as we can to get load times
  // not sure if this should be above or below the loader below
  if (process.env.REACT_APP_ENABLE_DD || (process.env.REACT_APP_ENV && IS_DEPLOYMENT_MODE)) {
    datadogRum.init({
      applicationId: "3a0be233-4d3e-4967-bbcb-1f5aa3f8fba5", //not a secret as it must be compiled into the app
      clientToken: "pub14cc7957eead903e996a688975a0634c", // not a secret as it must be comiled into the app
      site: "datadoghq.com",
      service: "sj_frontend",
      env: APP_HOST && APP_HOST.endsWith("dev") ? "development" : "production",
      version: process.env.GIT_SHA,
      sampleRate: 50,
      sessionReplaySampleRate: 100, // if not included, the default is 100
      traceSampleRate: 10,
      trackResources: true,
      trackLongTasks: true,
      trackInteractions: true,
      trackFrustrations: true,
      trackSessionAcrossSubdomains: true,
      useSecureSessionCookie: true,
      useCrossSiteSessionCookie: true,
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingOrigins: [
        /https:\/\/.*\.securityjourney\.(?:com|dev)/,
        /https:\/\/.*\.hackedu\.(?:com|dev)/,
      ],
      enableExperimentalFeatures: ["feature_flags"],
    });
    datadogRum.startSessionReplayRecording();

    datadogLogs.init({
      clientToken: "pub14cc7957eead903e996a688975a0634c", // not a secret as it must be comiled into the app
      site: "datadoghq.com",
      service: "sj_frontend",
      env: APP_HOST && APP_HOST.endsWith("dev") ? "development" : "production",
      version: process.env.GIT_SHA,
      forwardErrorsToLogs: true,
      sampleRate: 100,
      trackSessionAcrossSubdomains: true,
      useSecureSessionCookie: true,
      useCrossSiteSessionCookie: true,
      enableExperimentalFeatures: ["feature_flags"],
    });
  }
};
