import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import T from "prop-types";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";

import { useNoImmediateRefresh } from "./featureFlags";

import EmptyPageLoader from "./components/EmptyPageLoader";
import ToastFlashes from "./components/ToastFlashes";

import {
  API_HE_LOGIN_PATH,
  API_SAML_LOGIN_PATH,
  API_SAML_SJ_ADMIN_LOGIN_PATH,
  API_SJ_ADMIN_LOGIN_PATH,
  SJ_ADMIN_SIGN_IN_PATH,
} from "./routes/landing";
import { ADMIN_BASE } from "./admin/routes";
import {
  getIsImpersonating,
  getImpersonateToken,
  cleanUserArtifacts,
  setIsSjAdminToStorage,
} from "./helpers/user";
import { useEnableMfaForSjAdminFlag } from "./featureFlags";
import { getCognitoCurrentUser } from "./helpers/cognito";

const SUCCESS_REDIRECT_MODES = {
  user: { path: API_HE_LOGIN_PATH, redirectPath: "/" },
  sjAdmin: { path: API_SJ_ADMIN_LOGIN_PATH, redirectPath: ADMIN_BASE },
  samlUser: { path: API_SAML_LOGIN_PATH, redirectPath: "/" },
  samlSjAdmin: { path: API_SAML_SJ_ADMIN_LOGIN_PATH, redirectPath: ADMIN_BASE },
};

const ERROR_REDIRECT_MODES = {
  user: "/",
  sjAdmin: SJ_ADMIN_SIGN_IN_PATH,
  samlUser: "/",
  samlSjAdmin: SJ_ADMIN_SIGN_IN_PATH,
};

const HeRedirectApp = ({
  loginAsync,
  mode,
  loginErrorMessage,
  cognitoRefresh,
  update,
  setLoginErrorMessage,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [impersonationToken, setImpersonationToken] = useState(null);
  const isMfaEnabled = useEnableMfaForSjAdminFlag();
  const noImmediateRefresh = useNoImmediateRefresh();

  const onLogin = useCallback(async () => {
    const { code } = qs.parse(search);
    if (!code) {
      return setLoginErrorMessage(true);
    }

    if (getIsImpersonating()) {
      setImpersonationToken(getImpersonateToken());
    }

    const cognitoUser = getCognitoCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }
    cleanUserArtifacts();

    update({
      authToken: null,
      isAuthenticated: false,
      refreshToken: null,
      id: null,
    });

    if (impersonationToken && impersonationToken !== "null") {
      localStorage.setItem("impersonateAuthToken", impersonationToken);
    }

    const { path = API_HE_LOGIN_PATH, redirectPath = "/" } = SUCCESS_REDIRECT_MODES[mode];
    const isSjAdmin = window.location.pathname.includes("sj_admin_auth");
    const tenantId = localStorage.getItem("t");
    localStorage.removeItem("t");

    await loginAsync({
      path,
      code,
      redirect: false,
      isSjAdmin,
      isMfaEnabled,
      tenantId,
    });

    if (!noImmediateRefresh) await cognitoRefresh({ isSjAdmin });

    if (isSjAdmin) {
      setIsSjAdminToStorage();
    }

    navigate(redirectPath);
  }, [loginAsync, search]);

  useEffect(() => {
    if (!loginErrorMessage) {
      return;
    }

    toast.error(loginErrorMessage);

    setTimeout(() => {
      const redirectTo = ERROR_REDIRECT_MODES[mode] || "/";
      navigate(redirectTo);
    }, 4000);
  }, [loginErrorMessage]);

  useEffect(() => {
    onLogin();
  }, []);

  return (
    <>
      <EmptyPageLoader />
      <ToastFlashes />
    </>
  );
};

HeRedirectApp.propTypes = {
  loginAsync: T.func.isRequired,
  cognitoRefresh: T.func.isRequired,
  mode: T.string.isRequired,
  loginErrorMessage: T.string.isRequired,
  setLoginErrorMessage: T.func.isRequired,
  update: T.func.isRequired,
};

const mapState = ({ user: { loginErrorMessage } }) => ({
  loginErrorMessage,
});

const mapDispatch = ({ user: { cognitoRefresh, loginAsync, setLoginErrorMessage, update } }) => ({
  loginAsync,
  cognitoRefresh,
  setLoginErrorMessage,
  update,
});

export default connect(mapState, mapDispatch)(HeRedirectApp);
