import { jwtDecode } from "jwt-decode";

import { AuthClient } from "@securityjourney/svc-auth";
import { APP_URL_HOST } from "../constants/urls";

import { toast } from "react-toastify";
import { EXCHANGE_FAILED } from "../constants/rpcErrors";

export const REFRESH_TOKEN_PERIOD = 12 * 60 * 1000;

const CURRENT_SELECTED_TENANT = "currentSelectedTenant";
const IMPERSONATED_EMAIL = "impersonatedEmail";
const IS_SJ_ADMIN = "isSJAdmin";
const REDIRECTED_TO_ADMIN = "redirectedToAdmin";

export const cleanUserArtifacts = () => {
  localStorage.removeItem("idToken");
  localStorage.removeItem("meta");
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("impersonateAuthToken");
  localStorage.removeItem("isImpersonating");
  localStorage.removeItem("svcAuthToken");
  removeCurrentSelectedTenant();
  removeImpersonatedEmail();
  removeIsSjAdminFromStorage();
  removeRedirectedToAdminFromStorage();
};

export const removeCurrentSelectedTenant = () => localStorage.removeItem(CURRENT_SELECTED_TENANT);
export const removeImpersonatedEmail = () => localStorage.removeItem(IMPERSONATED_EMAIL);
export const removeIsSjAdminFromStorage = () => localStorage.removeItem(IS_SJ_ADMIN);
export const removeRedirectedToAdminFromStorage = () =>
  sessionStorage.removeItem(REDIRECTED_TO_ADMIN);

export const setUserArtifacts = artifacts => {
  localStorage.setItem("idToken", artifacts.idToken);
  if (artifacts.meta.length > 1) {
    localStorage.setItem("meta", JSON.stringify(artifacts.meta));
  } else {
    localStorage.setItem("meta", artifacts.meta);
  }
  localStorage.setItem("authToken", artifacts.accessToken);
  localStorage.setItem("refreshToken", artifacts.refreshToken);
};

export const getAuthenticated = () => !!localStorage.getItem("authToken");
export const getAuthToken = () => localStorage.getItem("authToken");
export const getSvcAuthToken = () => localStorage.getItem("svcAuthToken");
export const getImpersonateToken = () => localStorage.getItem("impersonateAuthToken");
export const getIsImpersonating = () => localStorage.getItem("isImpersonating");
export const getCurrentSelectedTenant = () => localStorage.getItem(CURRENT_SELECTED_TENANT);
export const getImpersonatedEmail = () => localStorage.getItem(IMPERSONATED_EMAIL);
export const getIsSjAdminFromStorage = () => localStorage.getItem(IS_SJ_ADMIN);

export async function handleImpersonating() {
  if (getImpersonateToken() !== null && getImpersonateToken() !== "") {
    localStorage.setItem("isImpersonating", true);
    return true;
  }
}
export const impersonateTokenExpired = () => {
  if (getImpersonateToken()) {
    const claims = jwtDecode(getImpersonateToken());
    return claims.exp < Date.now() / 1000;
  }
  return false;
};
export const idTokenExpired = () => {
  if (getIdToken()) {
    const claims = jwtDecode(getIdToken());
    return claims.exp < Math.floor(Date.now() / 1000);
  }
  return false;
};
export const getRefreshToken = () => localStorage.getItem("refreshToken");
export const getIdToken = () => localStorage.getItem("idToken");
export const getMeta = () => localStorage.getItem("meta");
export const getAccessTokenFromHtml = () =>
  document.querySelector("meta[name=access-token]").getAttribute("content");
export const getRefreshTokenFromHtml = () =>
  document.querySelector("meta[name=refresh-token]").getAttribute("content");
export const resetAccessTokenFromHtml = () =>
  document.querySelector("meta[name=access-token]").setAttribute("content", "");
export const resetRefreshTokenFromHtml = () =>
  document.querySelector("meta[name=refresh-token]").setAttribute("content", "");

export const saveAuthToken = token => localStorage.setItem("authToken", token);
export const saveSvcAuthToken = token => localStorage.setItem("svcAuthToken", token);
export const saveRefreshToken = token => localStorage.setItem("refreshToken", token);
export const saveIdToken = token => localStorage.setItem("idToken", token);
export const saveSelectedTenant = tenantName =>
  localStorage.setItem(CURRENT_SELECTED_TENANT, tenantName);

export const saveImpersonatedEmail = email => localStorage.setItem(IMPERSONATED_EMAIL, email);

export const setIsSjAdminToStorage = () => localStorage.setItem(IS_SJ_ADMIN, true);

export const getEmailFromJWT = jwt => {
  const claims = jwtDecode(jwt);

  if ("email" in claims) {
    return claims.email;
  } else if (
    "cognito:username" in claims &&
    claims["cognito:username"].includes("@") &&
    claims["cognito:username"].includes(".")
  ) {
    const username = claims["cognito:username"];

    const i = username.indexOf("_");
    const emailParts = [username.slice(0, i), username.slice(i + 1)];

    if (emailParts.length > 1) {
      return emailParts[1];
    }
    return emailParts[0];
  }

  return null;
};

export const getDeviceKeyFromJWT = jwt => {
  const claims = jwtDecode(jwt);
  if ("device_key" in claims) {
    return claims.device_key;
  }

  return null;
};

export const checkSVCExpired = () => {
  const claims = jwtDecode(getSvcAuthToken());
  if ("exp" in claims) {
    const curr = Math.floor(Date.now() / 1000);
    return claims.exp < curr;
  }

  return false;
};

const svcAuthClient = new AuthClient(APP_URL_HOST);

export const getSVCTokenFromJWT = async jwt => {
  return await svcAuthClient
    .exchangeForAccessToken(jwt)
    .promise.catch(e => {
      toast.error(`Error getting token: ${e.data.message}`);
    })
    .then(data => {
      if (data.code == EXCHANGE_FAILED) {
        toast.error(`Error getting token: ${data.message}`);
      } else {
        return data.access_token;
      }
    });
};

export const getSVCTokenTenantFromJWT = async (jwt, tenant) => {
  return await svcAuthClient
    .exchangeForAccessToken(jwt, { tenant: tenant })
    .promise.catch(e => {
      toast.error(`Error getting token: ${e.data.message}`);
    })
    .then(data => {
      return data.access_token;
    });
};

export const retrieveSvcAuthToken = async idToken => {
  const isSjAdmin = getIsSjAdminFromStorage();

  if (checkSVCExpired()) {
    const svcToken = await handleRefreshSvcAuthToken(idToken, { isSjAdmin });
    if (svcToken) saveSvcAuthToken(svcToken);
  }
  return getSvcAuthToken();
};

export const exchangeSvcTokenToImpersonateUser = async email => {
  const idToken = getIdToken();
  const svcAuthToken = await retrieveSvcAuthToken(idToken);

  const svcImpersonatingToken = await svcAuthClient.exchangeForAccessToken(svcAuthToken, {
    as: email,
  }).promise;

  if (svcImpersonatingToken?.access_token) {
    saveSvcAuthToken(svcImpersonatingToken.access_token);
    saveImpersonatedEmail(email);
    return svcImpersonatingToken?.access_token;
  } else {
    toast.error(`Error getting token: ${svcImpersonatingToken?.message}`);
    throw svcImpersonatingToken?.message;
  }
};

export const exchangeJwtTokenToGetSjAdminTenantAccess = async ({ tenantName, jwtToken }) => {
  const visitingTenantToken = await svcAuthClient.exchangeForAccessToken(jwtToken, {
    admin: tenantName,
  }).promise;

  if (visitingTenantToken?.access_token) {
    saveSvcAuthToken(visitingTenantToken.access_token);
    saveSelectedTenant(tenantName);
    return visitingTenantToken?.access_token;
  } else {
    toast.error(`Error getting token: ${visitingTenantToken?.message}`);
    throw visitingTenantToken?.message;
  }
};

export const handleRefreshSvcAuthToken = async (jwtToken, options = {}) => {
  const { isSjAdmin = false } = options;
  const scopes = determineScopes(isSjAdmin);
  return await exchangeAndSaveToken(jwtToken, scopes);
};

const determineScopes = isSjAdmin => {
  const impersonatedEmail = getImpersonatedEmail();
  const currentSjAdminTenant = getCurrentSelectedTenant();
  const isOperatingInTenant = !!(impersonatedEmail || currentSjAdminTenant);

  return {
    admin: isSjAdmin ? currentSjAdminTenant : null,
    as: isSjAdmin ? impersonatedEmail : null,
    sjAdmin: isSjAdmin && !isOperatingInTenant ? true : null,
  };
};

const exchangeAndSaveToken = async (jwtToken, scopes) => {
  const token = await svcAuthClient.exchangeForAccessToken(jwtToken, scopes).promise;
  if (token?.access_token) {
    saveSvcAuthToken(token.access_token);
    return token?.access_token;
  } else {
    toast.error(`Error getting token: ${token?.message}`);
    throw token?.message;
  }
};
