import getClient from "../client";
import { API_CHANGE_LOCALE } from "../routes/user";

export default {
  state: {},

  reducers: {
    setLocale: (state, payload) => ({ ...state, ...payload }),
  },

  effects: dispatch => ({
    async setCurrentLocale(locale) {
      try {
        const { data } = await getClient().post(API_CHANGE_LOCALE, { locale });
        dispatch.locale.setLocale(data);
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    },
  }),
};
