export const ACTIVATED_DEFAULT_PATHS = "sjPathsActivated";
export const ACHIEVEMENT_WALL_ENABLED = "achievementWall";
export const CATALOG_VIEW_ENABLED = "catalog";
export const GDPR_ENABLED = "gdpr";
export const STRICT_ASSIGNMENT = "strictAssignment";
export const LEADERBOARD_ENABLED = "leaderboard";
export const SEARCH_ENABLED = "search";
export const ANONYMOUS_LEADEBOARD_ENABLED = "anonymousLeaderboard";
export const EMAIL_ALL_DOJO_ADMIN = "emailAllDojoAdmins";
export const PARTISIPATION_EDITING_ENABLED = "participationEditing";
export const RANKING_QUESTIONS_DISABLED = "rankingQuestions";
export const PASSED_ANIMATION_ENABLED = "passedAnimation";
export const NUMBER_OF_TRIES = "numberOfTries";

export const SSO = "sso";

export const DOJO_PREVIEW_VIDEO_ID = "dojoPreviewVideoId";
export const EMAIL_CONGRATULATION_NOTIFICATIONS_ENABLED = "emailCongratulationNotifications";
export const EMAIL_NOTIFICATIONS_ENABLED = "emailNotifications";
export const EMAIL_UPDATE_FREQUENCY = "updateFrequency";
export const NUMBER_OF_TRIES_ENABLED = "numberOfTriesEnabled";

export const SECURITY_CONTACT = "contacts";
export const DOJO_DISCLAIMER = "disclaimerText";

export const COMPANY_LOGO = "smallLogoUrl";

export const ENROLLMENT_COMPLETION_NOTIFICATION_ENDPOINT =
  "enrollmentCompletionNotificationEndpoint";

export const WEBHOOK_ENABLED = "webhookEnabled";

export const SAML_APPROVE = "samlApprove";

export const LEARNER_UI = "learnerUi";

export const ALLOW_USER_PROFILE_EDITS = "allowUserProfileEdits";
