import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { jwtDecode } from "jwt-decode";
import { saveSelectedTenant } from "../../../../helpers/user";

import S from "./ChooseTenantModal.module.scss";

const ChooseTenantModal = ({ isOpen, setIsOpen }) => {
  const [tenants, setTenants] = useState([]);

  useEffect(() => {
    const isValidJwt = str => {
      try {
        var token = jwtDecode(str);
        return token.available_tenants.length > 1;
      } catch (e) {
        return false;
      }
    };

    const svc = localStorage.getItem("svcAuthToken");

    if (isValidJwt(svc)) {
      setIsOpen(true);
      prepareTenantData(svc);
    } else {
      setIsOpen(false);
    }
  }, []);

  const prepareTenantData = svcToken => {
    const tenants = [];

    const decodedToken = jwtDecode(svcToken);

    if (decodedToken.selected_tenant) {
      setIsOpen(false);
      return;
    }

    decodedToken.available_tenants.forEach(tenant => {
      tenants.push(tenant.name);
    });

    setTenants(tenants);
  };

  const handleSelectTenant = async index => {
    const selectedTenant = tenants[index];

    saveSelectedTenant(selectedTenant);

    setIsOpen(false);
    window.location.reload();
  };

  return (
    <Modal
      backdropClassName={S.backdrop}
      backdrop={true}
      className={S.root}
      isOpen={isOpen}
      centered
    >
      <ModalHeader tag="div">Which tenant would you like to log into?</ModalHeader>

      <p className={S.subHeader}>You may switch tenants at any time from your Account Profile.</p>

      <ModalBody testkey="select-tenant-modal">
        {tenants.map((tenant, index) => {
          return (
            <Button
              key={index}
              className={S.button}
              block
              outline
              testkey={`select-tenant-button-${tenant}`}
              onClick={() => handleSelectTenant(index)}
            >
              {tenant}
            </Button>
          );
        })}
      </ModalBody>
    </Modal>
  );
};

ChooseTenantModal.propTypes = {
  isOpen: T.bool.isRequired,
  setIsOpen: T.func.isRequired,
};

export default ChooseTenantModal;
