import React from "react";
import C from "classnames";
import networkErrorIcon from "../../assets/images/network-error-icon.png";
import sjLogoIcon from "../../assets/images/SJ-logo-small.png";

import S from "./NetworkError.module.scss";
import { DEMO_LINK } from "../../constants/links";
import { Container, Nav, Navbar, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

export const NetworkError = () => {
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className={S.errorPage}>
      <div className={S.navbarContainer}>
        <Navbar className={S.navbar}>
          <Nav className={S.nav}>
            <NavItem className={S.navItem}>
              <NavLink
                className={S.navLink}
                tag="a"
                href="https://www.securityjourney.com/ourstory"
              >
                Our story
              </NavLink>
            </NavItem>

            <NavItem className={S.navItem}>
              <NavLink className={S.navLink} tag="a" href="https://www.securityjourney.com/product">
                Our product
              </NavLink>
            </NavItem>
          </Nav>
          <Link to="#" className={S.navbarBrand} onClick={reloadPage}>
            <span className={S.logoImage}>
              <img className={S.stop} src={sjLogoIcon} alt="logo" />
            </span>
          </Link>
          <Nav className={S.nav}>
            <NavItem className={C(S.navItem, S.resourceItem)}>
              <NavLink
                className={S.navLink}
                tag="a"
                href="https://www.securityjourney.com/resources"
              >
                Resources
              </NavLink>
            </NavItem>
            <NavItem className={C(S.navItem, S.loginItem)}>
              <NavLink className={S.navLink} tag="a" href="/user/sign_in">
                Login
              </NavLink>
              <a href={DEMO_LINK} className={C("btn btn-warning", S.schedule)}>
                Schedule Demo
              </a>
            </NavItem>
          </Nav>
        </Navbar>
      </div>
      <Container className={S.container}>
        <div className={S.content}>
          <img className={S.stop} src={networkErrorIcon} alt="contentImage" />
          <h1>
            {`Well, this is a bit embarrassing. We've had a bit of a glitch with the Security Journey
            platform.`}
          </h1>
          <p>
            If you think you have reached this page in error, please contact your security team.
          </p>
          <p>
            <a href="/" className={C("btn btn-warning", S.schedule, S.returnHome)}>
              Return to home page
            </a>
          </p>
        </div>
      </Container>
    </div>
  );
};
