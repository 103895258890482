import { IS_DEPLOYMENT_MODE } from "../constants/env";

export const runIntercomScript = () => {
  if (!IS_DEPLOYMENT_MODE || window.location.pathname.includes("/guest/")) return;
  const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
  window.intercomSettings = { app_id: APP_ID };
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/" + APP_ID;
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
};

export const canSeeIntercom = shouldSee => {
  const intercomNode = document.querySelector(".intercom-lightweight-app");
  if (!intercomNode) return;
  intercomNode.style.display = shouldSee ? "block" : "none";
};
