import { IS_DEV_MODE, IS_DEPLOYMENT_MODE } from "./env";

export const APP_HOST = !IS_DEPLOYMENT_MODE ? "https://my.securityjourney.dev" : "";
export const APP_API_URL = process.env.REACT_APP_API_URL || `${window.location.origin}/api`;
export const APP_SERVER_HOST = process.env.REACT_APP_SERVER_HOST || `${window.location.host}/api`;
export const APP_URL_HOST = process.env.REACT_APP_APP_URL || "https://my.securityjourney.dev";

export const AWS_COGNITO_REDIRECT_URI =
  process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI || `${window.location.origin}/auth`;

export const AWS_COGNITO_SJ_ADMIN_REDIRECT_URI =
  process.env.REACT_APP_AWS_COGNITO_SJ_ADMIN_REDIRECT_URI ||
  `${window.location.origin}/sj_admin_auth`;

export const scimApiUrl = tenantName => {
  if (IS_DEV_MODE) {
    return `https://api.securityjourney.dev/scim/${tenantName}/v2`;
  } else {
    return `https://api.securityjourney.com/scim/${tenantName}/v2`;
  }
};
