import { EXPERIMENT_MISSION_PATH } from "../routes/user";

export const LESSON_PATH = { path: "/belts/:beltId/modules/:moduleId/lesson", component: "Lesson" };
export const CATALOG_LESSON_PATH = {
  path: "/modules/:moduleId/lesson",
  component: "Lesson",
};

export const ASSESSMENT_PATH = {
  path: "/belts/:beltId/modules/:moduleId/assessment",
  component: "Assessment",
};

export const CATALOG_ASSESSMENT_PATH = {
  path: "/modules/:moduleId/assessment",
  component: "Assessment",
};

export const EXPERIMENT_PATH = {
  path: "/belts/:beltId/modules/:moduleId/experiment",
  component: "Experiment",
};

export const CATALOG_EXPERIMENT_PATH = {
  path: "/modules/:moduleId/experiment",
  component: "Experiment",
};

export const MISSION_EXPERIMENT_PATH = {
  path: EXPERIMENT_MISSION_PATH,
  component: "ExperimentMission",
};
