import axios from "axios";
import humps from "humps";
import { errorHandler } from "./helpers/httpErrorHandler";
import { getHeaders } from "./helpers/headers";
import store from "./store";

const shouldRetryAuth = config => {
  if (!config.auth_retry) {
    config.auth_retry = 3;
  }
  config.auth_retry -= 1;
  return config.auth_retry > 0;
};

const getClient = (options = { useToast: true, forHeStatus: false }, httpHeaders = getHeaders) => {
  const client = axios.create({
    data: {},
    // transform snake_case response from rails
    // into camelCase
    transformResponse: [...axios.defaults.transformResponse, data => humps.camelizeKeys(data)],
    transformRequest: [data => humps.decamelizeKeys(data), ...axios.defaults.transformRequest],
  });

  client.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const status = error.response ? error.response.status : null;

      if (status === 503) {
        return store.dispatch.site.updateNetworkError(true);
      }

      if (status === 401 && shouldRetryAuth(error.config)) {
        return store.dispatch.user.cognitoRefresh({ isSjAdmin: false }).then(() => {
          error.config.headers = httpHeaders();
          return client.request(error.config);
        });
      }

      errorHandler(error, options);
      return Promise.reject(error);
    }
  );

  client.interceptors.request.use(
    async config => {
      const headers = await httpHeaders();
      config.headers = { ...config.headers, ...headers };
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  return client;
};

export default getClient;
