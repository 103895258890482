import passedLegend from "../../assets/images/list_view/legends/passed.svg";
import notStartedLegend from "../../assets/images/list_view/legends/not_yet_started.svg";
import inProgressLegend from "../../assets/images/list_view/legends/in_progress.svg";
import failedLegend from "../../assets/images/list_view/legends/failed.svg";
import optionalLegend from "../../assets/images/list_view/legends/optional.svg";

export const FULL_MODE_ALLOWED_IDS = [1, 3, 59];

export const HUMAN_RESULTS = {
  in_progress: "In Progress",
  consumed: "In Progress",
  "not started": "Not Started",
  passed: "Completed",
  not_started: "Not Started",
};

export const LESSONS_LEGENDS = {
  in_progress: {
    icon: inProgressLegend,
    result: "inProgress",
  },
  consumed: {
    result: "inProgress",
    icon: inProgressLegend,
  },
  "not started": {
    result: "notStarted",
    icon: notStartedLegend,
  },
  passed: {
    result: "passed",
    icon: passedLegend,
  },
  not_started: {
    result: "notStarted",
    icon: notStartedLegend,
  },
  failed: {
    result: "failed",
    icon: failedLegend,
  },
  optional: {
    result: "optional",
    icon: optionalLegend,
  },
};

export const LESSON_CONTEXTS = {
  course: { label: "path", title: "Path" },
  quest: { label: "quest", title: "Quest" },
  assignments: { label: "assignments", title: "Assignment" },
  fullCatalog: { label: "fullCatalog", title: "Full Catalog" },
  tournament: { label: "tournament", title: "Tournament" },
};
