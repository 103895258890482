import getClient from "../client";
import { API_CUSTOM_NOTIFICATIONS_PATH, updateCustomNotificationsPath } from "../routes/user";

export default {
  state: {
    lastNotification: null,
    blockHeight: 0,
  },

  reducers: {
    setLastNotification: (state, lastNotification) => ({
      ...state,
      lastNotification,
    }),
    setBlockHeight: (state, blockHeight) => ({ ...state, blockHeight }),
  },

  effects: dispatch => ({
    updateBlockHeight(blockHeight) {
      dispatch.customNotifications.setBlockHeight(blockHeight);
    },
    async loadAsync() {
      const { data } = await getClient().get(API_CUSTOM_NOTIFICATIONS_PATH);

      dispatch.customNotifications.setLastNotification(data);
    },

    async updateAsync({ id, skipUpdate = false }) {
      dispatch.customNotifications.setLastNotification(null);
      if (!skipUpdate) {
        await getClient().put(updateCustomNotificationsPath(id));
      }
    },

    async manuallyAddNotification(notification) {
      dispatch.customNotifications.setLastNotification(notification);
    },
  }),
};
