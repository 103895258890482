import { IS_PRODUCTION_MODE } from "../constants/env";

export const runAnalyticsScript = () => {
  if (!IS_PRODUCTION_MODE) return;
  const ANALYTIC_ID = process.envREACT_APP_GOOGLE_ANALYTIC_ID;
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", ANALYTIC_ID);
};
