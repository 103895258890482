export default {
  state: {
    hasPassedSubmissions: false,
  },

  reducers: {
    set: (state, payload) => ({ ...state, ...payload }),
  },

  effects: dispatch => ({
    async updatePassedSubmissions(hasPassedSubmissions) {
      dispatch.breakFix.set({ hasPassedSubmissions });
    },
  }),
};
