export const ACHIEVEMENT_WALL_ENABLED = "achievementWall";
export const CATALOG_VIEW_ENABLED = "catalog";
export const GDPR_ENABLED = "gdpr";
export const LEADERBOARD_ENABLED = "leaderboard";
export const SEARCH_ENABLED = "search";
export const ANONYMOUS_LEADEBOARD_ENABLED = "anonymousLeaderboard";
export const PARTISIPATION_EDITING_ENABLED = "participationEditing";
export const RANKING_QUESTIONS_DISABLED = "rankingQuestions";
export const PASSED_ANIMATION_ENABLED = "passedAnimation";
export const NUMBER_OF_TRIES = "numberOfTries";

export const SSO = "sso";

export const LEARNER_VIEW = "learner_view";
export const MAP_VIEW = "map_view";
export const LIST_VIEW = "list_view";
