import { matchRoutes, useLocation } from "react-router-dom";
import { useEffect, useState, useMemo, useRef } from "react";
import { useStore } from "react-redux";
import { API_TRACK_LESSON_TIME_PATH, API_TRACK_MISSION_EXPERIMENT_TIME_PATH } from "../routes/user";
import { useBeforeunload } from "react-beforeunload";
import { useTimeTracker } from "../featureFlags";

const DURATION = 5000;
const SEND_INTERVAL = 30000;
const COOKIE_NAME = "timeTracker";

const trackTimerRoutes = {
  Lesson: API_TRACK_LESSON_TIME_PATH,
  Assessment: API_TRACK_LESSON_TIME_PATH,
  Experiment: API_TRACK_LESSON_TIME_PATH,
  ExperimentMission: API_TRACK_MISSION_EXPERIMENT_TIME_PATH,
};

export const useTrackTime = ({ paths }) => {
  const isTimeTrackerEnabled = useTimeTracker();

  const location = useLocation();
  const {
    dispatch: {
      user: { trackTime },
    },
    getState,
  } = useStore();

  const {
    user: { isAuthenticated },
  } = getState();

  // Check if another visible window is tracking time
  const flagTrackingRef = useRef(null);

  // Ensure only one window is tracking time at a time
  // A user might have multiple windows visible at a time
  const exclusiveWindowTracking = () => {
    const cookieValue = localStorage.getItem(COOKIE_NAME);
    const refValue = flagTrackingRef.current;
    const currentTime = Date.now();

    // If we set the cookie, the cookie is unset,
    // or the cookie was set a long time ago, we can track.
    if (
      (refValue && cookieValue == refValue) ||
      !cookieValue ||
      cookieValue < currentTime - DURATION * 2
    ) {
      localStorage.setItem(COOKIE_NAME, currentTime);
      flagTrackingRef.current = currentTime;
      return true;
    }

    // Otherwise, another window set the cookie. We can't track.
    return false;
  };

  // Remove the tracking cookie
  useBeforeunload(() => {
    if (exclusiveWindowTracking()) {
      localStorage.removeItem(COOKIE_NAME);
    }
  });

  // Flag for if the user is on the current tab
  const [visible, setVisible] = useState(document.visibilityState == "visible");

  // Try to match the current location to the route
  const matchedRoute = useMemo(() => {
    const pathsList = Object.keys(paths).map(key => {
      return paths[key].path;
    });

    const routes = pathsList.map(path => ({ path }));
    const matchedRoutes = matchRoutes(routes, location);

    return matchedRoutes && matchedRoutes[0];
  }, [location, paths]);

  // Keeps visible up to date
  useEffect(() => {
    const updateVisible = () => setVisible(document.visibilityState == "visible");
    document.addEventListener("visibilitychange", updateVisible);
    return () => document.removeEventListener("visibilitychange", updateVisible);
  }, []);

  // The actual time tracking
  useEffect(() => {
    if (matchedRoute && visible && isTimeTrackerEnabled) {
      // Get details about the current path
      const currentPath = matchedRoute.route.path;
      const params = matchedRoute.params;
      const component = paths.find(path => path.path === currentPath).component;
      const timePath = trackTimerRoutes[component];

      // Start a timer to periodically send time updates
      let timer = 0;
      const timerInterval = setInterval(() => {
        if (exclusiveWindowTracking()) {
          timer += DURATION;
        }
      }, DURATION);

      const sendInterval = setInterval(() => {
        let duration = timer;
        timer = 0;

        trackTime({ component, duration, params, timePath });
      }, SEND_INTERVAL);
      return () => {
        clearInterval(timerInterval);
        clearInterval(sendInterval);
        isAuthenticated && trackTime({ component, duration: timer, params, timePath });
      };
    }
  }, [trackTime, matchedRoute, visible, paths, isTimeTrackerEnabled]);
};
