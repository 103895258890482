export const ABOUT_LINK = "https://www.securityjourney.com/about";
export const PRIVACY_LINK = "https://www.securityjourney.com/privacy";
export const PRODUCT_LINK = "https://www.securityjourney.com/secure-coding-training-platform";
export const RESOURCE_LINK = "https://www.securityjourney.com/resources";
export const STORY_LINK = "https://www.securityjourney.com/our-team";
export const TERMS_LINK = "https://www.securityjourney.com/terms-of-service";
export const HOME_LINK = "https://www.securityjourney.com";

export const DEMO_LINK =
  "https://www.securityjourney.com/coding-training-solutions/custom-demo-form?hsCtaTracking=aa7e1cb3-ff1c-4658-9a2b-8f80df209841%7C720e6cca-e29a-4696-bea2-f17cbcf356a6";

export const TRIAL_FULL_CATALOG = "https://catalog.securityjourney.com/catalog";

// Help Links
export const KNOWLEDGE_BASE = "https://help.securityjourney.com/en/";
export const CSV_UPLOAD_HELP = `${KNOWLEDGE_BASE}/articles/8945456-uploading-csv-files`;
