import axios from "axios";
import { toast } from "react-toastify";
import { buildHeaderForUpload } from "../helpers/headers";
import { API_FEEDBACK_PATH } from "../routes/user";
const TOAST_ID = "user-feedback";

const INITIAL_STATE = {
  isSubmitting: false,
  isSuccess: false,
};

export default {
  state: INITIAL_STATE,

  reducers: {
    setIsSubmitting: (state, isSubmitting) => ({ ...state, isSubmitting }),
    setIsSuccess: (state, isSuccess) => ({ ...state, isSuccess }),
  },

  effects: dispatch => ({
    async createAsync({ details, file, needImprove, from = "global", cb }, state) {
      try {
        toast.dismiss(TOAST_ID);
        const formData = new FormData();
        formData.append("details", details);
        formData.append("need_improve", needImprove);
        if (from === "assessment") {
          const { id: questionId } = state.user__assessment.question;
          formData.append("question_id", questionId);
        }
        if (file) {
          formData.append("file", file, file.name || "image.png");
        }
        dispatch.feedback.setIsSubmitting(true);
        await axios.post(API_FEEDBACK_PATH, formData, {
          headers: await buildHeaderForUpload(),
        });
        dispatch.feedback.setIsSuccess(true);
        cb();
      } catch (error) {
        if (error.response && error.response.data) {
          toast.error(error.response.data.errors, { toastId: TOAST_ID });
        }
      } finally {
        dispatch.feedback.setIsSubmitting(false);
      }
    },

    updateIsSuccessVisible(isSuccess) {
      dispatch.feedback.setIsSuccess(isSuccess);
    },
  }),
};
