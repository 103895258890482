import getClient from "../client";
import { API_SEARCH_PATH } from "../routes/user";

export default {
  state: {
    query: "",

    page: 1,
    results: [],
    pagesNumber: 1,
    resultsTotalNumber: 0,

    isLoading: false,
  },

  reducers: {
    updateState: (state, payload) => ({ ...state, ...payload }),
    setIsLoading: (state, isLoading) => ({ ...state, isLoading }),
  },

  effects: dispatch => ({
    async searchAsync({ query, locale }) {
      dispatch.search.updateState({ isLoading: true, query });

      try {
        const { data } = await getClient().get(API_SEARCH_PATH, {
          params: { query, locale },
        });

        const { results, page = 1, pagesNumber = 1, resultsTotalNumber } = data || {};

        if (results) {
          dispatch.search.updateState({
            results,
            page,
            pagesNumber,
            resultsTotalNumber,
          });
        }
      } catch (e) {
        console.log(e);
      }

      dispatch.search.setIsLoading(false);
    },

    async searchPageAsync(page, { search: { query = "" } }) {
      dispatch.search.updateState({ isLoading: true });

      try {
        const { data } = await getClient().get(API_SEARCH_PATH, {
          params: { query, page },
        });

        const { results, pagesNumber = 1, resultsTotalNumber } = data || {};

        if (results) {
          dispatch.search.updateState({
            results,
            page,
            pagesNumber,
            resultsTotalNumber,
          });
        }
      } catch (e) {
        console.log(e);
      }

      dispatch.search.setIsLoading(false);
    },
  }),
};
