import getClient from "../client";
import { TAGS_CATEGORIES_PATH } from "../routes/user";

export default {
  state: {
    tagsData: {
      tagsCategories: [],
      cweTags: [],
    },
    isLoading: true,
  },

  reducers: {
    setData: (state, data) => ({ ...state, tagsData: data }),
    setLoading: (state, isLoading) => ({ ...state, isLoading }),
  },

  effects: dispatch => ({
    async loadAsync() {
      dispatch.tagsCategories.setLoading(true);
      const { data } = await getClient().get(TAGS_CATEGORIES_PATH);

      if (data) {
        const tagsCategories = data.tagsCategories.filter(tc => tc.tags.length);
        const languageTags = tagsCategories.find(tc => tc.name.toLowerCase() === "language");
        const language = languageTags ? languageTags.tags : [];

        window.availableLanguages = language;

        const updatedData = { ...data, tagsCategories };
        dispatch.tagsCategories.setData(updatedData);
      }
      dispatch.tagsCategories.setLoading(false);
    },
  }),
};
