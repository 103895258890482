import getClient from "../client";
import { API_LATEST_CHANGES_PATH, API_UPDATE_CURRENT_USER_PATH } from "../routes/user";

export default {
  state: {
    latestChangesList: [],
    newLatestChanges: false,
  },
  reducers: {
    getState: state => ({
      ...state,
    }),
    setState: (state, latestChanges) => ({
      ...state,
      latestChangesList: [...latestChanges],
    }),
    setNewLatestChangesActiveState: (state, activeState) => ({
      ...state,
      newLatestChanges: activeState,
    }),
  },

  effects: dispatch => ({
    async loadAsync() {
      const { data } = await getClient().get(API_LATEST_CHANGES_PATH);
      if (data && data.latestChanges) {
        dispatch.latestChanges.setState(data.latestChanges);
      }
    },
    async addAsync(latestChange) {
      await getClient().post(API_LATEST_CHANGES_PATH, { latestChange });
    },

    async onNewLatestChangesSeen(_, { latestChanges: { latestChangesList, newLatestChanges } }) {
      try {
        if (newLatestChanges && latestChangesList.length > 0) {
          const lastDate = latestChangesList[0].createdAt;
          await getClient().put(API_UPDATE_CURRENT_USER_PATH, {
            user: { latestChangesLastDateViewed: lastDate },
          });
          dispatch.latestChanges.setNewLatestChangesActiveState(false);
        }
      } catch (e) {
        console.log("last viewed date hasn't been changed due error");
      }
    },

    updateNewLatestChangesActiveStates({ latestChangesLastDateViewed, latestChangesList }) {
      if (
        latestChangesList.length > 0 &&
        latestChangesLastDateViewed !== latestChangesList[0].createdAt
      ) {
        dispatch.latestChanges.setNewLatestChangesActiveState(true);
      } else {
        dispatch.latestChanges.setNewLatestChangesActiveState(false);
      }
    },
  }),
};
