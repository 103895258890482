import { init } from "@rematch/core";
import loadingPlugin from "@rematch/loading";

import * as models from "./models";
import { IS_PRODUCTION_MODE } from "./constants/env";

const store = init({
  redux: {
    devtoolOptions: {
      disabled: IS_PRODUCTION_MODE,
    },
  },
  models,
  plugins: [loadingPlugin()],
});

export const { select } = store;

export default store;
