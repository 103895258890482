import { datadogRum } from "@datadog/browser-rum";
import React from "react";
class DDErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    const renderingError = new Error(error.message);
    renderingError.name = "ReactRenderingError";
    renderingError.stack = info.componentStack;
    renderingError.cause = error;

    datadogRum.addError(renderingError);
  }

  render() {
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export { DDErrorBoundary };
