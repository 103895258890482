export const cognitoPoolData = ({ isSjAdmin = false, isMfaEnabled }) => {
  const userPoolId = getCorrectPoolId(isSjAdmin, isMfaEnabled);
  const clientId = getCorrectClientId(isSjAdmin, isMfaEnabled);

  return {
    UserPoolId: userPoolId,
    ClientId: clientId,
  };
};

const getCorrectPoolId = (isSjAdmin, isMfaEnabled) => {
  if (isSjAdmin && isMfaEnabled) {
    return process.env.REACT_APP_AWS_COGNITO_USER_POOL_ADMIN_ID || window.awsCognitoUserPoolAdminId;
  } else {
    return process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID || window.awsCognitoUserPoolId;
  }
};

const getCorrectClientId = (isSjAdmin, isMfaEnabled) => {
  if (isSjAdmin && isMfaEnabled) {
    return process.env.REACT_APP_AWS_COGNITO_CLIENT_ADMIN_ID || window.awsCognitoClientAdminId;
  } else {
    return process.env.REACT_APP_AWS_COGNITO_CLIENT_ID || window.awsCognitoClientId;
  }
};
