import { DEMO_LINK } from "../constants/links";
import { ADMIN_BASE } from "../admin/routes";
import { HOME_PATH, LEARN_PATH } from "../routes/user";
import { matchPath } from "react-router-dom";

export const goToDemo = () => {
  window.location.href = DEMO_LINK;
};

export const goToAdminHome = () => {
  window.location.href = ADMIN_BASE;
};

export const goToHome = () => {
  window.location.href = HOME_PATH;
};

export const isLessonPage = () => {
  return [LEARN_PATH].some(path => matchPath(path, location.pathname));
};
