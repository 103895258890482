import { toast } from "react-toastify";
import i18next from "i18next";
import {
  FORBIDDEN,
  NOT_FOUND,
  PAYMENT_REQUIRE,
  SERVER_ERROR,
  UNAUTHORIZED,
  UNPROCESSABLE_ENTITY,
} from "../constants/httpErrorStatuses";
import store from "../store";

const STATUS_MESSAGES = {
  [PAYMENT_REQUIRE]: i18next.t("errors.402"),
  [FORBIDDEN]: i18next.t("errors.403"),
  [NOT_FOUND]: i18next.t("errors.404"),
  [UNPROCESSABLE_ENTITY]: i18next.t("errors.422"),
};

export const generateMessageFromStatus = ({ status, data }) => {
  let message;

  if (status === UNAUTHORIZED) {
    store.dispatch.user.cognitoRefresh({ isSjAdmin: false });
  }

  if (status >= SERVER_ERROR) {
    message = i18next.t("errors.somethingWentWrong");
  } else if (STATUS_MESSAGES[status]) {
    message = STATUS_MESSAGES[status];
  }

  if ([FORBIDDEN, UNPROCESSABLE_ENTITY].includes(status)) {
    // user should know why he is not allowed
    if (data && data.errors && typeof data.errors === "string") {
      message = data.errors;
    }
  }
  return message;
};

export const fetchErrorToastHandler = ({ message, status, useToast }) => {
  if (message && !toast.isActive(status)) {
    const isCypress = store.getState().site.isCypress;
    const attributes = !isCypress
      ? { toastId: status }
      : { toastId: status, position: "top-center", autoClose: 50000 };
    if (useToast) {
      toast.error(message, attributes);
    } else {
      toast.dismiss(status);
    }
  }
};

export const networkErrorToastHandler = () => {
  const networkError = "network-error";
  if (!toast.isActive(networkError)) {
    toast.error(i18next.t("errors.networkProblem"), {
      toastId: networkError,
    });
  }
};
