import React, { Suspense, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import T from "prop-types";
import { Routes, Route, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { jwtDecode } from "jwt-decode";
import { getCurrentSelectedTenant } from "./helpers/user";
import { runIntercomScript } from "./helpers/intercom";
import { runAnalyticsScript } from "./helpers/analytics";
import EmptyPageLoader from "./components/EmptyPageLoader";
import ChooseTenantModal from "./user/components/common/modals/ChooseTenantModal";
import ChooseTenantModalMeta from "./user/components/common/modals/ChooseTenantModalMeta";
import { useEnableSvcAuth, useAdminRedirectOnLogin } from "./featureFlags";
import {
  AFTER_SIGN_OUT_PATH,
  HE_LOGIN_PATH,
  HE_SJ_ADMIN_LOGIN_PATH,
  SAML_LOGIN_PATH,
  SAML_REDIRECT_SIGN_IN_PATH,
  SAML_SJ_ADMIN_LOGIN_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  SJ_ADMIN_SIGN_IN_PATH,
  VERIFY_EMAIL_PATH,
  HOME_PATH,
} from "./routes/landing";
import { ADMIN_BASE } from "./admin/routes";
import HeRedirectApp from "./HeRedirectApp";
import { useTrackTime } from "./hooks/useTrackTime";
import {
  LESSON_PATH,
  ASSESSMENT_PATH,
  EXPERIMENT_PATH,
  MISSION_EXPERIMENT_PATH,
  CATALOG_LESSON_PATH,
  CATALOG_EXPERIMENT_PATH,
  CATALOG_ASSESSMENT_PATH,
} from "./constants/trackablePaths";
import { SIGN_IN_LINK } from "./landing/routes";
import { SCORM_PATH_PREFIX } from "./routes/scorm";
import FullScreenPreloader from "./components/FullScreenPreloader";
import { NetworkError } from "./components/NetworkError/NetworkError";
import { datadogRum } from "@datadog/browser-rum";
import { runSegmentIo } from "./helpers/segmentIo";

const FrontendApp = React.lazy(() => import("./FrontendApp"));
const AdminFrontendApp = React.lazy(() => import("./AdminFrontendApp"));
const SjAdminApp = React.lazy(() => import("./sjadmin/SjAdminApp"));
const LandingApp = React.lazy(() => import("./landing/LandingApp"));

const TryUsApp = React.lazy(() => import("./TryUsFrontendApp"));
const ScormApp = React.lazy(() => import("./scorm/ScormApp"));

const REDIRECT_PATHS = [
  SAML_SJ_ADMIN_LOGIN_PATH,
  SAML_LOGIN_PATH,
  HE_LOGIN_PATH,
  HE_SJ_ADMIN_LOGIN_PATH,
];

const NO_REDIRECT_TO_PATHS = [
  ...REDIRECT_PATHS,
  AFTER_SIGN_OUT_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  SAML_REDIRECT_SIGN_IN_PATH,
  "//",
];

const App = ({
  isAuthenticated,
  trialMode,
  email,
  name,
  userId,
  isVariablesLoaded,
  loadAsyncVariables,
  isNetworkError,
  hasAdminAccess,
  isSiteLoaded,
}) => {
  useTrackTime({
    paths: [
      LESSON_PATH,
      ASSESSMENT_PATH,
      EXPERIMENT_PATH,
      MISSION_EXPERIMENT_PATH,
      CATALOG_LESSON_PATH,
      CATALOG_ASSESSMENT_PATH,
      CATALOG_EXPERIMENT_PATH,
    ],
  });

  const [isOpenChooseTenantModal, setIsOpenChooseTenantModal] = useState(true);
  const navigation = useNavigate();
  const location = useLocation();

  // Intercom
  // SegmentIO
  useEffect(() => {
    runIntercomScript();
    runSegmentIo();
    loadAsyncVariables();
  }, [loadAsyncVariables]);

  // Redirect to old url after login
  const [searchParams] = useSearchParams();
  const tenant = searchParams.get("t");

  const shouldSaveRedirect = !(
    location.pathname.startsWith(SCORM_PATH_PREFIX) ||
    NO_REDIRECT_TO_PATHS.includes(location.pathname) ||
    location.pathname.startsWith(VERIFY_EMAIL_PATH.replace(":code", ""))
  );

  const isAdminRedirectFlagEnabled = useAdminRedirectOnLogin();

  useEffect(() => {
    const redirectUrl = localStorage.getItem("redirectAfterLoginUrl");
    const alreadyRedirectedToAdmin = sessionStorage.getItem("redirectedToAdmin");
    const shouldRedirectToAdmin =
      hasAdminAccess && !alreadyRedirectedToAdmin && isAdminRedirectFlagEnabled;

    if (!isAuthenticated) {
      if (shouldSaveRedirect && !tenant) {
        if (location.pathname == HOME_PATH) navigation(SIGN_IN_LINK + location.search);
        localStorage.setItem("redirectAfterLoginUrl", location.pathname + location.search);
      }
    } else {
      if (!isSiteLoaded) return;
      if (redirectUrl) {
        localStorage.removeItem("redirectAfterLoginUrl");

        navigation(redirectUrl);
      } else if (shouldRedirectToAdmin) {
        if (location.pathname === HOME_PATH) {
          sessionStorage.setItem("redirectedToAdmin", "true");

          navigation(ADMIN_BASE);
        }
      }
    }
  }, [
    isAuthenticated,
    location,
    navigation,
    shouldSaveRedirect,
    tenant,
    hasAdminAccess,
    isSiteLoaded,
  ]);

  // Launch Darkly Client
  const ldClient = useLDClient();
  const svcAuthToken = localStorage.getItem("svcAuthToken");
  if (!email && svcAuthToken) {
    email = jwtDecode(svcAuthToken).email;
    if (!userId) userId = jwtDecode(svcAuthToken).directory_uuid;
  }
  useEffect(() => {
    if (!email) return;
    ldClient.identify({
      kind: "user",
      key: userId,
      email: email,
      name: name,
      privateAttributeNames: ["email", "name"],
    });
  }, [email, userId]);

  const isSjAdmin = meta => {
    if (!meta) return false;
    const claims = jwtDecode(meta);
    if ("is_admin" in claims) {
      return claims.is_admin;
    }
    return false;
  };

  const useSvcAuth = useEnableSvcAuth();
  sessionStorage.setItem("useSvcAuth", useSvcAuth);

  // DataDog User/Tenant Information
  useEffect(() => {
    if (!email || !userId) return;
    datadogRum.setUser({
      id: userId,
      name: name,
      email: email,
      tenant: tenant,
    });
  }, [email, ldClient, name, userId, tenant]);

  // Google Tags Manager
  useEffect(() => {
    if (!trialMode) return;
    runAnalyticsScript();
  }, [trialMode]);

  // Other info
  const isRedirectAppUrl = REDIRECT_PATHS.includes(location.pathname);

  // The various routes which can be taken
  // Anything in the memo list will could cause the app to reload
  return useMemo(() => {
    if (isNetworkError) return <NetworkError />;

    if (!isVariablesLoaded) return <FullScreenPreloader />;

    if (isRedirectAppUrl) {
      return (
        <Routes>
          <Route path={SAML_SJ_ADMIN_LOGIN_PATH} element={<HeRedirectApp mode="samlSjAdmin" />} />
          <Route path={SAML_LOGIN_PATH} element={<HeRedirectApp mode="samlUser" />} />
          <Route path={HE_LOGIN_PATH} element={<HeRedirectApp mode="user" />} />
          <Route path={HE_SJ_ADMIN_LOGIN_PATH} element={<HeRedirectApp mode="sjAdmin" />} />
        </Routes>
      );
    }

    if (isSjAdmin(localStorage.getItem("meta")) || getCurrentSelectedTenant() != null) {
      setIsOpenChooseTenantModal(false);
    }

    return (
      <Suspense fallback={<EmptyPageLoader />}>
        {isOpenChooseTenantModal &&
          (useSvcAuth ? (
            <ChooseTenantModal
              isOpen={isOpenChooseTenantModal}
              setIsOpen={setIsOpenChooseTenantModal}
            />
          ) : (
            <ChooseTenantModalMeta
              isOpen={isOpenChooseTenantModal}
              setIsOpen={setIsOpenChooseTenantModal}
            />
          ))}
        <>
          {!isOpenChooseTenantModal && (
            <>
              {isAuthenticated && (
                <Routes>
                  <Route path={`${ADMIN_BASE}/*`} element={<AdminFrontendApp />} />
                  <Route path="/*" element={<FrontendApp />} />
                  <Route path="/tryus/*" element={<TryUsApp />} />
                  <Route path="/guest/*" element={<ScormApp />} />
                </Routes>
              )}

              {!isAuthenticated && (
                <Routes>
                  <Route path={SJ_ADMIN_SIGN_IN_PATH} element={<SjAdminApp />} />
                  <Route path="/*" element={<LandingApp />} />
                  <Route path="/tryus/*" element={<TryUsApp />} />
                  <Route path="/guest/*" element={<ScormApp />} />
                </Routes>
              )}
            </>
          )}
        </>
      </Suspense>
    );
  }, [
    isAuthenticated,
    isVariablesLoaded,
    isRedirectAppUrl,
    isOpenChooseTenantModal,
    isNetworkError,
  ]);
};

App.propTypes = {
  isAuthenticated: T.bool.isRequired,
  userId: T.number,
  email: T.string,
  name: T.string,
  trialMode: T.bool.isRequired,
  isLoaded: T.bool,
  loadAsyncVariables: T.func.isRequired,
  isVariablesLoaded: T.bool.isRequired,
  isNetworkError: T.bool.isRequired,
  isSiteLoaded: T.bool,
  hasAdminAccess: T.bool.isRequired,
};

const mapState = ({
  user: { id: userId, isAuthenticated, isLoaded, email, name },
  site: { trialMode, isNetworkError, hasAdminAccess, isLoaded: isSiteLoaded },
  variables: { isVariablesLoaded },
}) => ({
  isAuthenticated,
  trialMode,
  userId,
  isLoaded,
  email,
  name,
  isVariablesLoaded,
  isNetworkError,
  isSiteLoaded,
  hasAdminAccess,
});

const mapDispatch = ({ variables: { loadAsyncVariables } }) => ({ loadAsyncVariables });

export default connect(mapState, mapDispatch)(App);
