import getClient from "../client";
import { ROLES_AUDIENCES_PATH } from "../routes/user";

export default {
  state: {
    rolesAudiences: [],
  },

  reducers: {
    set: (state, payload) => ({ ...state, ...payload }),
  },

  effects: dispatch => ({
    async loadRolesAudiences() {
      const { data } = await getClient().get(ROLES_AUDIENCES_PATH);
      dispatch.rolesAudiences.set({ rolesAudiences: data });
    },
  }),
};
