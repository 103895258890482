import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { runDataLog } from "./helpers/datalog";
import { DDErrorBoundary } from "./components/DDErrorBoundary";
import App from "./App";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { datadogRum } from "@datadog/browser-rum";
import { PrimeReactProvider } from "primereact/api";

import store from "./store";

import "./styles/react.scss";
import {
  IS_DEPLOYMENT_MODE,
  IS_DEV_MODE,
  IS_LOCAL_MODE,
  IS_PRODUCTION_MODE,
  IS_DEPLOYED_DEV_MODE,
  IS_TEST_MODE,
} from "./constants/env";

runDataLog();

(async () => {
  if (IS_DEV_MODE) {
    console.log("START DEV MODE WITH ENV", {
      NODE_ENV: process.env.NODE_ENV,
      REACT_APP_ENV: process.env.REACT_APP_ENV,
      IS_DEPLOYMENT_MODE,
      IS_LOCAL_MODE,
      IS_PRODUCTION_MODE,
      IS_STAGING_MODE: IS_DEPLOYED_DEV_MODE,
      IS_TEST_MODE,
      IS_DEV_MODE,
    });
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_ID,
    bootstrap: "localStorage",
    options: {
      inspectors: [
        {
          type: "flag-used",
          name: "dd-inspector",
          method: (key, detail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  });
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    <LDProvider>
      <Provider store={store}>
        <DDErrorBoundary>
          <BrowserRouter>
            <PrimeReactProvider>
              <App role="main" />
            </PrimeReactProvider>
          </BrowserRouter>
        </DDErrorBoundary>
      </Provider>
    </LDProvider>
  );
})();
