import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import S from "./ToastFlashes.module.scss";

const CloseButton = ({ closeToast = () => {} }) => (
  <button onClick={closeToast} className={S.button}>
    <FontAwesomeIcon icon={faTimes} />
  </button>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

const ToastFlashes = () => (
  <ToastContainer
    hideProgressBar={true}
    autoClose={3000}
    theme="colored"
    draggable={false}
    pauseOnHover={false}
    closeOnClick={false}
    position={toast.POSITION.BOTTOM_RIGHT}
  />
);

export default ToastFlashes;
